import { Loading } from "@/components";
import { Button } from "@/components/ui/button";
import {
  subscriptionsQuery,
  updateRegistryDataMutation,
} from "@/services/queries";
import { cn } from "@/lib/utils";
import smallBox from "@/assets/photos/50/small.webp";
import mediumBox from "@/assets/photos/50/medium.webp";
import bigBox from "@/assets/photos/50/big.webp";
import fruitsBox from "@/assets/photos/50/fruits.webp";
import { formatCurrency } from "@/utils/currency";
import { CheckCircle2 } from "lucide-react";
import { useEffect, useState } from "react";
import PastBoxes from "@/serverComponents/PastBoxes/PastBoxes";

type SubscriptionsProps = {
  initialSelectedSubscription: number;
  onGoBack: () => void;
};

const IMAGES = {
  "3": smallBox,
  "1": mediumBox,
  "2": bigBox,
  "4": fruitsBox,
};

const Subscriptions = ({
  onGoBack,
  initialSelectedSubscription,
}: SubscriptionsProps) => {
  const { data: subscriptionsResponse, isLoading: subscriptionsIsLoading } =
    subscriptionsQuery();
  const [selectedSubscription, setSelectedSubscription] = useState(
    initialSelectedSubscription,
  );
  const [showPastBoxes, setShowPastBoxes] = useState(false);
  const mutation = updateRegistryDataMutation();

  // Redirect to summary if the mutation is successful
  useEffect(() => {
    if (mutation.isSuccess) {
      onGoBack();
    }
  }, [mutation.isSuccess]);

  const subscriptions = subscriptionsResponse?.data;

  if (subscriptionsIsLoading) return <Loading />;

  if (!subscriptions) return null;

  const selectedSubscriptionData = subscriptions.find(
    (subscription) => subscription.id === selectedSubscription,
  );

  const handleChangeSubscription = () => {
    mutation.mutate({
      form_subscription_id: selectedSubscription,
    });
  };

  return (
    <div className="space-y-4">
      <div className="grid grid-cols-4 gap-4 mt-4">
        {subscriptions.map((subscription) => (
          <div
            key={subscription.id}
            className="relative cursor-pointer"
            onClick={() => setSelectedSubscription(subscription.id)}
          >
            {subscription.id === 1 && (
              <div
                className={cn(
                  "bg-primary text-[10px] text-white font-semibold text-center rounded-tl-md rounded-tr-md absolute w-full -top-[13px]",
                  selectedSubscription !== 1 && "bg-gray-400",
                )}
              >
                Más popular
              </div>
            )}
            <div
              className={cn(
                "border rounded-md p-2",
                selectedSubscription === subscription.id &&
                  "border-primary border-2 bg-primary/10",
                subscription.id === 1 && "rounded-tl-none rounded-tr-none",
              )}
            >
              <h5 className="text-sm font-semibold leading-none mb-2">
                {subscription.name}
              </h5>
              <div className="flex items-center justify-between">
                <p className="font-sans text-primary font-semibold">
                  {formatCurrency(subscription.price_incl_tax)}
                </p>
                {selectedSubscription === subscription.id && (
                  <CheckCircle2 className="w-4 h-4 text-primary hidden md:block" />
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div>
        <div className="flex justify-center">
          <img
            className="w-3/4"
            src={
              IMAGES[`${selectedSubscriptionData?.id}` as keyof typeof IMAGES]
            }
          />
        </div>
        <div className="flex justify-between border-b py-2">
          <p className="text-sm text-muted-foreground">Tamaño de caja</p>
          <p className="text-sm font-semibold">
            {selectedSubscriptionData?.name}
          </p>
        </div>
        <div className="flex justify-between border-b py-2">
          <p className="text-sm text-muted-foreground">Recomendado para</p>
          <p className="text-sm font-semibold">
            {selectedSubscriptionData?.aprox_people} personas.
          </p>
        </div>
        <div className="flex justify-between border-b py-2">
          <p className="text-sm text-muted-foreground">Variedad</p>
          <p className="text-sm font-semibold">
            {selectedSubscriptionData?.aprox_quantity} variedades de frutas
            {selectedSubscriptionData?.id !== 4 ? " y verduras" : ""}.
          </p>
        </div>
        <div className="flex justify-between border-b py-2">
          <p className="text-sm text-muted-foreground">Peso</p>
          <p className="text-sm font-semibold">
            {selectedSubscriptionData?.aprox_weight.split("a ")[0]}kg aprox.
          </p>
        </div>
      </div>
      <div>
        {showPastBoxes && selectedSubscriptionData?.id && (
          <PastBoxes
            subscriptionId={selectedSubscriptionData.id}
            onClose={() => setShowPastBoxes(false)}
          />
        )}
        <button
          type="button"
          onClick={() => setShowPastBoxes(true)}
          className="text-sm text-primary underline w-full text-center"
        >
          ¿Qué contiene una {selectedSubscriptionData?.name}?
        </button>
      </div>
      <div className="grid grid-cols-2 gap-4 pt-2">
        <Button
          variant="outline"
          disabled={mutation.isPending}
          onClick={() => onGoBack()}
        >
          Volver
        </Button>
        <Button
          disabled={mutation.isPending}
          onClick={handleChangeSubscription}
        >
          {mutation.isPending ? "Cambiando..." : "Seleccionar"}
        </Button>
      </div>
    </div>
  );
};

export default Subscriptions;
