import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { CalendarIcon, AlertCircle } from "lucide-react";
import { Label } from "@/components/ui/label";
import { cn } from "@/lib/utils";
import { updateRegistryDataMutation } from "@/services/queries";
import { Frecuency } from "@/services/types";
import { useEffect, useState } from "react";
import { es } from "date-fns/locale";
import { dateStringToDatetime, datetimeToDateString } from "@/utils/dates";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

type DeliveryDateProps = {
  frecuencies: Frecuency[];
  initialDeliveryDate: string;
  onGoBack: () => void;
};

const DeliveryDate = ({
  frecuencies,
  initialDeliveryDate,
  onGoBack,
}: DeliveryDateProps) => {
  const selectedDeliveryDateString = frecuencies.find(
    (f) => f.name === initialDeliveryDate,
  )?.date;
  const [selectedDeliveryDate, setSelectedDeliveryDate] = useState(
    selectedDeliveryDateString
      ? dateStringToDatetime(selectedDeliveryDateString)
      : undefined,
  );

  const selectedDeliveryName = selectedDeliveryDate
    ? frecuencies.find(
        (f) => f.date === datetimeToDateString(selectedDeliveryDate),
      )?.name
    : undefined;

  const mutation = updateRegistryDataMutation();

  const sortedFrecuencies = [...frecuencies].sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
  );

  // Redirect to summary if the mutation is successful
  useEffect(() => {
    if (mutation.isSuccess) {
      onGoBack();
    }
  }, [mutation.isSuccess]);

  const handleChangeDeliveryDate = () => {
    mutation.mutate({
      form_shipping_first_delivery_date: selectedDeliveryName,
    });
  };

  const [open, setOpen] = useState(false);

  return (
    <div>
      <div className="mb-4 flex items-center gap-2 rounded-md border border-border px-2 py-2 bg-primary/10">
        <AlertCircle size={32} className="text-primary" />
        <p className="text-xs text-muted-foreground font-semibold">
          Basado en tu comuna y la frecuencia de entrega estas son las fechas de
          entrega disponibles:
        </p>
      </div>
      <div className="mb-4">
        <Label>Fecha primera entrega</Label>
        <Popover open={open} onOpenChange={setOpen}>
          <PopoverTrigger asChild>
            <Button
              variant={"outline"}
              className={cn(
                "w-full justify-start text-left font-normal text-sm flex gap-2",
                !selectedDeliveryDate && "text-muted-foreground",
              )}
            >
              <CalendarIcon size={16} />
              {selectedDeliveryName ? (
                `${selectedDeliveryName}`
              ) : (
                <span>Escoge una fecha</span>
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start">
            <Calendar
              mode="single"
              className="font-sans"
              selected={selectedDeliveryDate}
              onSelect={(d) => {
                setSelectedDeliveryDate(d!);
                setOpen(false);
              }}
              fromDate={dateStringToDatetime(sortedFrecuencies[0].date)}
              disabled={(date) => {
                return !sortedFrecuencies
                  .map((f) => f.date)
                  .includes(datetimeToDateString(date));
              }}
              toDate={dateStringToDatetime(
                sortedFrecuencies[sortedFrecuencies.length - 1].date,
              )}
              locale={es}
              initialFocus
            />
          </PopoverContent>
        </Popover>
      </div>
      <div className="mb-6">
        <p className="text-sm text-muted-foreground">
          {" "}
          {/* La fecha de entrega se calcula a partir de la fecha de tu primera
          compra. */}
        </p>
      </div>
      <div className="grid grid-cols-2 gap-4 pt-2">
        <Button
          variant="outline"
          disabled={mutation.isPending}
          onClick={() => onGoBack()}
        >
          Volver
        </Button>
        <Button
          disabled={mutation.isPending || !selectedDeliveryDate}
          onClick={handleChangeDeliveryDate}
        >
          {mutation.isPending ? "Cambiando..." : "Seleccionar"}
        </Button>
      </div>
    </div>
  );
};

export default DeliveryDate;
