import { ShippingType } from "@/services/types";
import { createContext, useContext, useEffect, useState } from "react";
import { config } from "@/config";
import { userRegistryDataQuery } from "@/services/queries";
import { Loading } from "@/components";
import * as Sentry from "@sentry/react";

// This fields are globally available on the register
export type RegistryData = {
  promo_code?: string;
  subscription_id?: string;
  shipping_type?: ShippingType;
  shipping_frecuency?: string;
  shipping_entity_id?: number;
  delivery_district?: string;
  pickup_district?: string;
  pickup_name?: string;
  address?: string;
  address_dpto?: string;
  aditional_notes?: string;
  shipping_first_delivery_date?: Date;
  display_name?: string;
  phone_number?: string;
  email?: string;
  password?: string;
  referrer_user_id?: string;
  referrer_code?: string;
  subscription_status?: string;
  payment_subscription_error?: boolean;
  partnership_data?: {
    name: string;
    metadata: object;
  };
};

type RegisterContextType = {
  registryData: RegistryData | null;
  setRegistryData: React.Dispatch<React.SetStateAction<RegistryData | null>>;
};
const RegisterContext = createContext<RegisterContextType | null>(null);

export const useRegister = () => {
  const context = useContext(RegisterContext);
  if (!context) {
    throw new Error("useRegister can't be used outside RegisterProvider");
  }
  return context;
};

export const RegisterProvider = ({
  children,
  saveInLocalStorage = true,
}: {
  children: React.ReactNode;
  saveInLocalStorage?: boolean;
}) => {
  // Try to get initial data from localstorage
  let savedRegistryData = "{}";
  try {
    savedRegistryData =
      localStorage.getItem(config.REGISTER_LOCALSTORAGE_KEY) || "{}";
  } catch (error) {
    Sentry.captureException(error);
  }

  const [registryData, setRegistryData] = useState<RegistryData | null>(
    JSON.parse(savedRegistryData),
  );

  const userRegistryDataQ = userRegistryDataQuery();

  useEffect(() => {
    const remoteRegistryData = userRegistryDataQ.data;
    // Overwrite registryData if it has a firebase record of the user
    if (remoteRegistryData && remoteRegistryData.subscription_status) {
      setRegistryData(userRegistryDataQ.data);
    }
  }, [userRegistryDataQ.data]);

  useEffect(() => {
    if (saveInLocalStorage) {
      try {
        localStorage.setItem(
          config.REGISTER_LOCALSTORAGE_KEY,
          JSON.stringify(registryData),
        );
      } catch (error) {
        Sentry.captureException(error);
      }
    }
  }, [registryData]);

  return (
    <RegisterContext.Provider value={{ registryData, setRegistryData }}>
      {userRegistryDataQ.isLoading ? (
        <div className="h-screen flex flex-col items-center justify-center">
          <Loading message="🍊 Cargando Maifud..." />
        </div>
      ) : (
        children
      )}
    </RegisterContext.Provider>
  );
};
