import { useSearch } from "@tanstack/react-router";
import { PageLayout } from "@/components";
import type { ResumeCheckoutSearchSchema } from "@/routes/rcheckout";
import SubscriptionIncomplete from "../UserSubscription/SubscriptionIncomplete";

const ResumeCheckout = () => {
  const { uid, error: pageError } = useSearch({
    strict: false,
  }) as ResumeCheckoutSearchSchema;

  return (
    <PageLayout>
      <div className="pt-8 md:pt-0">
        {uid && <SubscriptionIncomplete uid={uid} hasError={pageError} />}
      </div>
    </PageLayout>
  );
};

export default ResumeCheckout;
