import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useNavigate } from "@tanstack/react-router";
import * as z from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { ImportantMessage, Loading, RetryErrorMessage } from "@/components";
import { addressDataQuery } from "@/services/queries";
import { useRegister, type RegistryData } from "@/context/register";

const pickUpFormSchema = z.object({
  pickup_name: z.string({
    required_error:
      "⚠️ Necesitamos la comuna para saber que puntos de retiro mostrarte",
  }),
  pickup_district: z.string({
    required_error:
      "⚠️ Necesitamos el punto de retiro para saber a donde enviar tus cajas",
  }),
});

export type PickUpFormSchema = z.infer<typeof pickUpFormSchema>;

type PickUpStoreFormProps = {
  initialData: RegistryData | null;
  // companyRef is the identifier of companies that have special pickup points
  companyRef?: string;
  onSubmit: (data: PickUpFormSchema) => void;
};

/**
 * PickUpStoreForm is only used on the registry flow
 */
const PickUpStoreForm = ({
  onSubmit,
  companyRef,
  initialData,
}: PickUpStoreFormProps) => {
  const { setRegistryData } = useRegister();
  const navigate = useNavigate();
  const [selectedDistrict, setSelectedDistrict] = useState(
    initialData?.pickup_district,
  );
  const [selectedPickUpPointName, setSelectedPickUpPointName] = useState(
    initialData?.pickup_name,
  );

  const {
    data: response,
    isLoading,
    isError,
    error,
    refetch,
  } = addressDataQuery(companyRef);
  const data = response?.data;

  const form = useForm<PickUpFormSchema>({
    resolver: zodResolver(pickUpFormSchema),
    defaultValues: initialData ?? {},
  });

  function handleSubmit(values: PickUpFormSchema) {
    onSubmit(values);
  }

  if (isLoading) {
    return <Loading message="🔍 Buscando información puntos de retiro..." />;
  }

  if (isError) {
    return (
      <RetryErrorMessage
        title={"No se pudo obtener la información de los puntos de retiro."}
        message={error.message}
        retry={refetch}
        error={error}
      />
    );
  }

  if (data) {
    const { pickup_point_district_names, pickup_points, districts } = data;

    const pickupPointsFromSelectedDistrict = pickup_points.filter(
      (p) => p["district_name"] === selectedDistrict,
    );

    const selectedPickUpPoint = pickupPointsFromSelectedDistrict.find(
      (p) => p.name === selectedPickUpPointName,
    );

    return (
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-6">
          <FormField
            control={form.control}
            name="pickup_district"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Comuna</FormLabel>
                <Select
                  onValueChange={(value) => {
                    field.onChange(value);
                    setSelectedDistrict(value);
                    setRegistryData((r) => ({
                      ...r,
                      pickup_district: value,
                      shipping_entity_id: districts.find(
                        (d) => d.district_name === value,
                      )?.id,
                    }));
                  }}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Selecciona una comuna" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {pickup_point_district_names.map((districtName) => (
                      <SelectItem key={districtName} value={districtName}>
                        {districtName}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="pickup_name"
            render={({ field }) => {
              const selectedPickupPoint = data.pickup_points.find(
                (p) => p.name === field.value,
              );
              return (
                <FormItem>
                  <FormLabel>Punto de retiro</FormLabel>
                  <Select
                    onValueChange={(value) => {
                      field.onChange(value);
                      setSelectedPickUpPointName(value);
                      setRegistryData((r) => ({
                        ...r,
                        pickup_name: value,
                      }));
                    }}
                    defaultValue={field.value}
                    disabled={selectedDistrict === undefined}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Selecciona un punto de retiro" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {/* TODO: replace name to id when there's a different endpoint to get the pick up points */}
                      {pickupPointsFromSelectedDistrict.map((p) => (
                        <SelectItem key={p.name} value={p.name}>
                          {p.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  {selectedPickupPoint && (
                    <FormDescription>
                      Costo de envío: {selectedPickupPoint.pickup_price}
                    </FormDescription>
                  )}
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          {selectedPickUpPoint && (
            <div className="mt-4">
              <ImportantMessage
                title={<p className="text-base">📍{selectedPickUpPointName}</p>}
                message={
                  <div className="w-full">
                    <div className="mb-2">
                      <a
                        className="underline decoration-orange-500 text-primary font-semibold"
                        href={`https://maps.google.com/maps?hl=es&q=${selectedPickUpPoint?.address} - ${selectedDistrict}`}
                      >
                        {selectedPickUpPoint?.address} - {selectedDistrict}
                      </a>
                      <p>
                        Horario de atención:{" "}
                        <strong className="text-primary">
                          {selectedPickUpPoint?.humanized_shipping_days} |{" "}
                          {selectedPickUpPoint?.opening_hours}
                        </strong>
                      </p>
                    </div>
                    <iframe
                      width="100%"
                      height="300"
                      src={`https://maps.google.com/maps?width=400&height=400&hl=es&q=${selectedPickUpPoint?.address} - ${selectedPickUpPoint.district_name}&t=&z=14&ie=UTF8&iwloc=B&output=embed`}
                    ></iframe>
                  </div>
                }
              />
            </div>
          )}
          <div className="flex justify-between py-4 gap-4">
            <Button
              type="button"
              variant="outline"
              className="w-full sm:w-fit"
              onClick={() => navigate({ to: "/register/subscriptions" })}
            >
              Volver
            </Button>
            <Button type="submit" className="w-full sm:w-fit">
              Siguiente
            </Button>
          </div>
        </form>
      </Form>
    );
  }
};

export default PickUpStoreForm;
