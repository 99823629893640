import axios from "axios";
import {
  PersonalData,
  UserSubscriptionData,
  AdressData,
  FrecuencyData,
  FrecuencyInput,
  UserHomeData,
  UserPaymentData,
  CheckoutInput,
  CheckoutOutput,
  UserSubscriptionDataV2,
  BoxRecipeResponse,
  SubscriptionsResponse,
  ReferralCheckResponse,
  PauseSuscription,
  PauseSuscriptionPost,
  DeleteSuscriptionPost,
  UserShippingData,
  Product,
  Subscription,
  LatestBoxContent,
  ConfirmBoxInput,
  UserBoxContent,
  ResumeCheckoutInput,
  ResumeCheckoutOutput,
  ResumeCheckoutDeliveryDateInput,
  Discount,
  PartnershipCheckInput,
  PartnershipCheckOutput,
  SummaryCartProduct,
  CartError,
  SubscriptionStatusResponse,
  OrderDetailsResponse,
  OrderPaymentsTotalResponse,
  OrderListItem,
  HomeRegistryData,
} from "@/services/types";
import { config } from "@/config";
import { SubscriptionEditSchema } from "@/components/SubscriptionEditForm/SubscriptionEditForm";
import { HomeDeliveryData } from "@/components/ShippingForm/HomeDelivery";
import { PickUpDeliveryData } from "@/components/ShippingForm/PickupDelivery";
import { getToken, getUIDParam, getUserUID } from "./utils";

export const fetchAdressData = (companyRef?: string) => {
  return axios.get<AdressData>(
    `${config.BACKEND_URL}/api/v1/initial_wizard/address${companyRef ? `?ref=${companyRef}` : ""}`,
  );
};

export const fetchPersonalData = async () => {
  const token = await getToken();
  return axios.get<PersonalData>(
    `${config.BACKEND_URL}/api/v1/preferences/user_profile`,
    {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        ...getUIDParam(),
      },
    },
  );
};

export const postPersonalData = async (data: PersonalData) => {
  const token = await getToken();
  return axios.post<PersonalData>(
    `${config.BACKEND_URL}/api/v1/preferences/user_profile`,
    data,
    {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        ...getUIDParam(),
      },
    },
  );
};

export const fetchUserSubscription = async () => {
  const token = await getToken();
  return axios.get<UserSubscriptionData>(
    `${config.BACKEND_URL}/api/v1/preferences/edit_subscription`,
    {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        ...getUIDParam(),
      },
    },
  );
};

export const postUserSubscription = async (data: SubscriptionEditSchema) => {
  const token = await getToken();
  return axios.post<any>(
    `${config.BACKEND_URL}/api/v1/preferences/edit_subscription`,
    data,
    {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        ...getUIDParam(),
      },
    },
  );
};

export const fetchSubscriptionsData = ({
  promo_code,
  referrer_code,
  partnership_data,
}: {
  promo_code?: string;
  referrer_code?: string;
  partnership_data?: string;
}) => {
  return axios.get<SubscriptionsResponse>(
    `${config.BACKEND_URL}/api/v1/initial_wizard/subscriptions`,
    {
      params: {
        promo_code,
        referrer_code,
        partnership_data,
        ...getUIDParam(),
      },
    },
  );
};

export const postFrecuency = async (data: FrecuencyInput) => {
  return axios.post<FrecuencyData>(
    `${config.BACKEND_URL}/api/v1/initial_wizard/frecuency`,
    {
      ...data,
    },
    {
      params: {
        ...getUIDParam(),
      },
    },
  );
};

export const fetchUserHomeData = async () => {
  const token = await getToken();
  return axios.get<UserHomeData>(
    `${config.BACKEND_URL}/api/v1/frontpages/home`,
    {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        ...getUIDParam(),
      },
    },
  );
};

export const fetchSubscriptions = async () => {
  return axios.get<Subscription[]>(
    `${config.BACKEND_URL}/api/v1/frontpages/subscriptions`,
    {
      params: {
        ...getUIDParam(),
      },
    },
  );
};

export const fetchUserHasDeliveryOnSpecialWeek = async () => {
  const token = await getToken();
  return axios.get<{
    has_delivery_on_special_week: boolean;
    next_delivery_date: string;
    user_already_chose: boolean;
    selected_delivery_date: string | null;
  }>(`${config.BACKEND_URL}/api/v1/frontpages/sept_survey`, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      ...getUIDParam(),
    },
  });
};

export const postDeliveryDateForSpecialWeek = async (
  selectedOption: "MONDAY" | "TUESDAY" | "POSTPONE",
) => {
  const token = await getToken();
  return axios.post<{ sucess: boolean }>(
    `${config.BACKEND_URL}/api/v1/frontpages/sept_survey`,
    {
      selected_option: selectedOption,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        ...getUIDParam(),
      },
    },
  );
};

export const fetchPaymentMethods = async () => {
  const token = await getToken();
  return axios.get<UserPaymentData>(
    `${config.BACKEND_URL}/api/v1/preferences/user_payment_methods/list`,
    {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        ...getUIDParam(),
      },
    },
  );
};

export const updatePaymentMethod = async (id: number) => {
  const token = await getToken();
  return axios.post<{ status: string }>(
    `${config.BACKEND_URL}/api/v1/preferences/user_payment_methods/update`,
    {
      payment_method_id: id,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        ...getUIDParam(),
      },
    },
  );
};

export const deletePaymentMethod = async (id: number) => {
  const token = await getToken();
  return axios.post<{ status: string }>(
    `${config.BACKEND_URL}/api/v1/preferences/user_payment_methods/delete`,
    {
      payment_method_id: id,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        ...getUIDParam(),
      },
    },
  );
};

export const fetchCheckout = async (checkoutInput: CheckoutInput) => {
  const token = await getToken();
  return axios.get<CheckoutOutput>(
    `${config.BACKEND_URL}/api/v1/initial_wizard/checkoutV2`,
    {
      headers: { Authorization: `Bearer ${token}` },
      // new_frontend is a flag that the backend recognizes to change the response
      params: { ...checkoutInput, ...getUIDParam() },
    },
  );
};

export const fetchResumeCheckout = async (
  resumeCheckoutInput: ResumeCheckoutInput,
) => {
  const token = await getToken();
  return axios.get<ResumeCheckoutOutput>(
    `${config.BACKEND_URL}/api/v1/resume_wizard/checkout`,
    {
      headers: { Authorization: `Bearer ${token}` },
      // new_frontend is a flag that the backend recognizes to change the response
      params: { ...resumeCheckoutInput, ...getUIDParam() },
    },
  );
};

export const updateRegistryData = async (data?: Partial<HomeRegistryData>) => {
  const token = await getToken();
  return axios.post<{ status: "OK" }>(
    `${config.BACKEND_URL}/api/v1/resume_wizard/update_register_data`,
    data,
    {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        ...getUIDParam(),
      },
    },
  );
};

export const searchProducts = async (search: string) => {
  const token = await getToken();
  return axios.get<Product[]>(`${config.BACKEND_URL}/api/v1/products`, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      search,
      ...getUIDParam(),
    },
  });
};

export const getRecommendedProducts = async () => {
  const token = await getToken();
  return axios.get<Product[]>(
    `${config.BACKEND_URL}/api/v1/products/recommended`,
    {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        ...getUIDParam(),
      },
    },
  );
};

export const getAllProducts = async () => {
  const token = await getToken();
  return axios.get<Product[]>(`${config.BACKEND_URL}/api/v1/products/all`, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      ...getUIDParam(),
    },
  });
};

export const getUserPurchasedfProducts = async () => {
  const token = await getToken();
  return axios.get<string[]>(`${config.BACKEND_URL}/api/v1/products/user`, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      ...getUIDParam(),
    },
  });
};

export const getBestSellerProducts = async () => {
  const token = await getToken();
  return axios.get<{ name: string; image: string }[]>(
    `${config.BACKEND_URL}/api/v1/products/best_seller`,
    {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        ...getUIDParam(),
      },
    },
  );
};

export const purchaseUpsellProducts = async (
  cart: SummaryCartProduct[],
  total: number,
) => {
  const token = await getToken();
  return axios.post<{
    status: "error" | "ok";
    error_code: CartError;
    details?: any;
  }>(
    `${config.BACKEND_URL}/api/v1/products/checkout`,
    {
      cart: JSON.stringify(cart),
      total,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        ...getUIDParam(),
      },
    },
  );
};

// TODO: Refactor fetchUserSubscription since it retrives similar data
export const fetchUserSubscriptionData = async () => {
  const token = await getToken();
  return axios.get<UserSubscriptionDataV2>(
    `${config.BACKEND_URL}/api/v1/preferences/menu`,
    {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        ...getUIDParam(),
      },
    },
  );
};

export const fetchBoxContent = async () => {
  const token = await getToken();
  return axios.get<BoxRecipeResponse>(
    `${config.BACKEND_URL}/b/box_suggestion/`,
    {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        ...getUIDParam(),
      },
    },
  );
};

export const postUserInputToGetBoxSuggestion = async (userInput?: string) => {
  const token = await getToken();
  return axios.post<BoxRecipeResponse>(
    `${config.BACKEND_URL}/b/box_suggestion/`,
    {
      user_input: userInput,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        ...getUIDParam(),
      },
    },
  );
};

export const getReferral = (referralCode?: string) => {
  return axios.get<ReferralCheckResponse>(
    `${config.BACKEND_URL}/m/user_referral/?referral_code=${referralCode}`,
    {
      params: {
        ...getUIDParam(),
      },
    },
  );
};

export const getPauseSuscription = async () => {
  const token = await getToken();
  return axios.get<PauseSuscription>(
    `${config.BACKEND_URL}/api/v1/preferences/pause_subscription`,
    {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        ...getUIDParam(),
      },
    },
  );
};

export const postPauseSuscription = async ({
  selected_next_shipping,
}: PauseSuscriptionPost) => {
  const token = await getToken();
  return axios.post<PauseSuscription>(
    `${config.BACKEND_URL}/api/v1/preferences/pause_subscription`,
    { selected_next_shipping },
    {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        ...getUIDParam(),
      },
    },
  );
};

export const postDeleteSuscription = async (values: DeleteSuscriptionPost) => {
  const token = await getToken();
  return axios.post<PauseSuscription>(
    `${config.BACKEND_URL}/api/v1/preferences/cancel_subscription`,
    values,
    {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        ...getUIDParam(),
      },
    },
  );
};

export const getUserShippingData = async () => {
  const token = await getToken();
  return axios.get<UserShippingData>(
    `${config.BACKEND_URL}/s/user_subscription_address/`,
    {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        ...getUIDParam(),
      },
    },
  );
};

export const postUserShippingData = async (
  values: HomeDeliveryData | PickUpDeliveryData,
) => {
  const token = await getToken();
  return axios.post<any>(
    `${config.BACKEND_URL}/s/user_subscription_address/`,
    values,
    {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        ...getUIDParam(),
      },
    },
  );
};

export const postRetryPayment = async () => {
  const token = await getToken();
  const userUID = await getUserUID();

  const overwriteUID = getUIDParam();

  return axios.post<PauseSuscription>(
    `${config.BACKEND_URL}/api/v1/preferences/user_payment_methods/retry_payment`,
    { user_uid: overwriteUID.uid ? overwriteUID.uid : userUID },
    {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        ...getUIDParam(),
      },
    },
  );
};

export const getLatestBoxContent = async (options?: {
  productlessResponse: boolean;
}) => {
  const token = await getToken();
  const productlessResponse = options?.productlessResponse;
  return axios.get<LatestBoxContent>(
    `${config.BACKEND_URL}/api/v1/boxes/latest`,
    {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        ...getUIDParam(),
        // boxes/latest supports productless response to make it faster
        productless_response: productlessResponse,
      },
    },
  );
};

export const postResetBox = async () => {
  const token = await getToken();
  return axios.post<PauseSuscription>(
    `${config.BACKEND_URL}/b/box_reset/`,
    undefined,
    {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        ...getUIDParam(),
      },
    },
  );
};

export const postConfirmBox = async (values: ConfirmBoxInput) => {
  const token = await getToken();
  return axios.post<any>(`${config.BACKEND_URL}/b/box_confirmation/`, values, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      ...getUIDParam(),
    },
  });
};

export const postResumeCheckoutDeliveryDate = async (
  values: ResumeCheckoutDeliveryDateInput,
) => {
  return axios.post<any>(
    `${config.BACKEND_URL}/s/precheckout_resume/`,
    values,
    {
      params: {
        ...getUIDParam(),
      },
    },
  );
};

export const getUserOrders = async () => {
  const token = await getToken();
  return axios.get<OrderListItem[]>(`${config.BACKEND_URL}/api/v1/orders`, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      ...getUIDParam(),
    },
  });
};

export const getOrderProducts = async (orderId?: number) => {
  const token = await getToken();
  return axios.get<UserBoxContent>(
    `${config.BACKEND_URL}/api/v1/orders/${orderId}/products`,
    {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        ...getUIDParam(),
      },
    },
  );
};

export const getOrderDetails = async (orderId?: number) => {
  const token = await getToken();
  return axios.get<OrderDetailsResponse>(
    `${config.BACKEND_URL}/api/v1/orders/${orderId}/details`,
    {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        ...getUIDParam(),
      },
    },
  );
};

export const getOrderPaymentsTotal = async (orderId?: number) => {
  const token = await getToken();
  return axios.get<OrderPaymentsTotalResponse>(
    `${config.BACKEND_URL}/api/v1/orders/${orderId}/total_paid`,
    {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        ...getUIDParam(),
      },
    },
  );
};

export const getDiscounts = async () => {
  const token = await getToken();
  return axios.get<Discount[]>(`${config.BACKEND_URL}/api/v1/discounts/`, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      ...getUIDParam(),
    },
  });
};

export const getUpsellDiscount = async () => {
  const token = await getToken();
  return axios.get<{ upsell_discount: number }>(
    `${config.BACKEND_URL}/api/v1/upsell_discount/`,
    {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        ...getUIDParam(),
      },
    },
  );
};

export const postCheckPartnership = async (values: PartnershipCheckInput) => {
  return axios.post<PartnershipCheckOutput>(
    `${config.BACKEND_URL}/p/check/`,
    { partnership_data: values },
    {
      params: {
        ...getUIDParam(),
      },
    },
  );
};

export const postSendCodePartnership = async (values: { email: string }) => {
  return axios.post<{ status: "OK" | "ERROR"; error_code: "INVALID_DOMAIN" }>(
    `${config.BACKEND_URL}/p/verify_email/`,
    values,
    {
      params: {
        ...getUIDParam(),
      },
    },
  );
};

export const postVerifyCodePartnership = async (values: {
  code: string;
  email: string;
}) => {
  return axios.post<{ status: "OK" | "ERROR"; error_code?: "INVALID_CODE" }>(
    `${config.BACKEND_URL}/p/verify_code/`,
    values,
    {
      params: {
        ...getUIDParam(),
      },
    },
  );
};

export const getSubscriptionStatus = async () => {
  const token = await getToken();
  return axios.get<SubscriptionStatusResponse>(
    `${config.BACKEND_URL}/api/v1/subscriptions/status`,
    {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        ...getUIDParam(),
      },
    },
  );
};
