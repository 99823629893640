import { Link } from "@tanstack/react-router";
import SVGSeparator from "./SVGSeparator";
import { useUser } from "@/firebase";
import { CheckIcon } from "lucide-react";
import { Skeleton } from "@/components/ui/skeleton";
import boxBig from "@/assets/photos/original/big.webp";

const HeroImage = () => {
  const { user, isLoading } = useUser();

  const renderCallToAction = () => {
    if (isLoading) {
      return <Skeleton className="w-[250px] h-[60px] bg-orange-500/30" />;
    }
    if (user?.email) {
      return (
        <button
          type="button"
          className="bg-primary text-primary-foreground rounded-md text-lg md:text-xl"
        >
          <Link
            to="/user/subscription"
            className="py-4 px-6 font-raleway block"
          >
            Ir a tu cuenta
          </Link>
        </button>
      );
    } else {
      return (
        <>
          <div className="flex flex-col md:flex-row gap-4">
            <button
              type="button"
              className="bg-primary text-primary-foreground rounded-md text-lg md:text-xl hover:shadow-lg"
            >
              <Link
                to="/register/subscriptions"
                className="py-4 px-6 font-raleway block"
              >
                Quiero mi suscripción
              </Link>
            </button>
            <a
              href="https://tienda.maifud.cl/"
              target="_blank"
              className=" text-white bg-[#7B7D2A] text-center py-2 px-6  rounded-md flex items-center justify-center text-lg hover:shadow-lg"
            >
              Comprar a granel
            </a>
          </div>
          <Link to="/login" className="underline text-lg">
            Iniciar sesión
          </Link>
        </>
      );
    }
  };

  return (
    <>
      <div className="relative lg:pb-8 bg-[#f6e9dd]">
        <div className="pt-6 lg:pt-0 px-30 flex flex-wrap flex-col lg:flex-row items-center max-w-7xl	mx-auto relative lg:h-[320px]">
          {/* <!--Left Col--> */}
          <div className="flex flex-col w-full relative z-10 justify-center items-center text-center pb-6 md:w-2/4 lg:text-left lg:items-start lg:pl-16">
            <h1 className="mt-2 mb-4 lg:my-4 text-2xl lg:text-3xl font-bold leading-tight px-2 lg:px-0">
              Combate el desperdicio de alimentos con tu caja de frutas y
              verduras imperfectas
            </h1>
            <ul className="leading-normal text-lg px-4 lg:px-0 lg:text-2xl mb-6 lg:mb-8 text-muted-foreground space-y-2 flex flex-col items-center lg:items-start">
              <li className="flex items-center gap-1">
                <CheckIcon color="#f97317" />{" "}
                <span>Personaliza el contenido de tu caja.</span>
              </li>
              <li className="flex items-center gap-1">
                <CheckIcon color="#f97317" />
                <span>Ahorra tiempo y dinero.</span>
              </li>
              <li className="flex items-center gap-1">
                <CheckIcon color="#f97317" />
                <span>Recibe en la puerta de tu casa.</span>
              </li>
            </ul>
            <div className="w-full flex flex-col space-y-4 items-center justify-center lg:w-fit lg:items-start lg:justify-start">
              {renderCallToAction()}
            </div>
          </div>
          {/* <!--Right Col--> */}
          <div className="w-full md:w-2/4 pt-6 lg:py-6 lg:flex lg:justify-end">
            <img
              className="w-full z-30"
              src={boxBig}
              alt="Caja de maifud llena de frutas y verduras"
            />
          </div>
        </div>
      </div>
      <SVGSeparator />
    </>
  );
};
export default HeroImage;
