import { createFileRoute } from "@tanstack/react-router";
import { Subscriptions as SubscriptionsPage } from "@/pages";
import * as z from "zod";

const subscriptionsQueryParamsSchema = z.object({
  promo_code: z.optional(z.string()),
  referrer_code: z.optional(z.string()),
  subs_id: z.optional(z.number()),
  partnership_data: z.optional(
    z.object({ name: z.string(), metadata: z.record(z.string()) }),
  ),
  // used to render special pickup points based on a partnetship string identifier
  ref: z.optional(z.string()),
});

export type SubscriptionsQueryParamsSchema = z.infer<
  typeof subscriptionsQueryParamsSchema
>;

export const Route = createFileRoute("/register/subscriptions")({
  component: SubscriptionsPage,
  validateSearch: subscriptionsQueryParamsSchema,
  beforeLoad: () => {
    document.title = "Maifud - Registro Suscripciones";
  },
});
