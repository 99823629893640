import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { useEffect } from "react";
import { updateRegistryDataMutation } from "@/services/queries";
import { useState } from "react";
import weeklyImage from "@/assets/weekly.png";
import biweeklyImage from "@/assets/biweekly.png";

type FrecuencyProps = {
  onGoBack: () => void;
  initialFrecuency: "Semanal" | "Quincenal";
};

const Frecuency = ({ onGoBack, initialFrecuency }: FrecuencyProps) => {
  const [selectedFrecuency, setSelectedFrecuency] = useState(initialFrecuency);
  const mutation = updateRegistryDataMutation();

  // Redirect to summary if the mutation is successful
  useEffect(() => {
    if (mutation.isSuccess) {
      onGoBack();
    }
  }, [mutation.isSuccess]);

  const handleChangeFrecuency = () => {
    mutation.mutate({
      form_shipping_frecuency: selectedFrecuency,
    });
  };

  return (
    <div>
      <div className="grid grid-cols-2 gap-4 mb-8">
        <div
          onClick={() => setSelectedFrecuency("Semanal")}
          className={cn(
            "p-2 rounded-md border border-border text-center flex flex-col items-center",
            selectedFrecuency === "Semanal" &&
              "border-primary border-2 bg-primary/10 font-semibold",
          )}
        >
          <div className="w-24 py-2">
            <img src={weeklyImage} alt="Icono de plan semanal" />
          </div>
          <span>Semanal</span>
        </div>
        <div
          onClick={() => setSelectedFrecuency("Quincenal")}
          className={cn(
            "p-2 rounded-md border border-border text-center flex flex-col items-center",
            selectedFrecuency === "Quincenal" &&
              "border-primary border-2 bg-primary/10 font-semibold",
          )}
        >
          <div className="w-24 py-2">
            <img src={biweeklyImage} alt="Icono de plan quincenal" />
          </div>
          <span>Quincenal</span>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 pt-2">
        <Button
          variant="outline"
          disabled={mutation.isPending}
          onClick={() => onGoBack()}
        >
          Volver
        </Button>
        <Button disabled={mutation.isPending} onClick={handleChangeFrecuency}>
          {mutation.isPending ? "Cambiando..." : "Seleccionar"}
        </Button>
      </div>
    </div>
  );
};

export default Frecuency;
