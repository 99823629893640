import { Link, useNavigate, useSearch } from "@tanstack/react-router";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import {
  Loading,
  PageLayout,
  Subscription,
  RetryErrorMessage,
} from "@/components";
import { Form, FormField } from "@/components/ui/form";
import { RadioGroup } from "@/components/ui/radio-group";
import { Button } from "@/components/ui/button";
import { subscriptionsDataQuery } from "@/services/queries";
import { useRegister } from "@/context/register";
import { SubscriptionsQueryParamsSchema } from "@/routes/register/subscriptions";
import { useUser } from "@/firebase";
import { useScrollToTop } from "@/utils/hooks";

const subscriptionsSchema = z.object({
  subscription_id: z.string(),
});

function Subscriptions() {
  useScrollToTop();
  // Get initial data from registry context
  const { registryData, setRegistryData } = useRegister();

  const { user } = useUser();

  let {
    promo_code = registryData?.promo_code,
    referrer_code = registryData?.referrer_code,
    subs_id,
    ref,
  } = useSearch({
    strict: false,
  }) as SubscriptionsQueryParamsSchema;

  // If both promo_code and referrer_code are provided. Only use promo_code
  if (promo_code && referrer_code) {
    referrer_code = undefined;
  }

  const {
    data: response,
    isLoading,
    isError,
    error,
    refetch,
  } = subscriptionsDataQuery({
    promo_code,
    referrer_code,
    partnership_data: registryData?.partnership_data
      ? JSON.stringify(registryData?.partnership_data)
      : undefined,
  });
  const data = response?.data;

  const navigate = useNavigate();

  const form = useForm<z.infer<typeof subscriptionsSchema>>({
    resolver: zodResolver(subscriptionsSchema),
    defaultValues: {
      subscription_id: registryData?.subscription_id ?? String(subs_id),
    },
  });

  const selectedSubscription = form.watch("subscription_id");

  function onSubmit({ subscription_id }: z.infer<typeof subscriptionsSchema>) {
    // Don´t save referrer_user_id if promo code is provided
    const referrer_user_id =
      !promo_code && data?.referrer?.referrer_user_id
        ? String(data?.referrer?.referrer_user_id)
        : undefined;
    setRegistryData({
      ...registryData,
      promo_code,
      subscription_id,
      referrer_code,
      referrer_user_id,
    });
    navigate({
      to: "/register/shipping-methods",
      search: {
        ref,
      },
    });
  }

  const isNextStepDisable = () => {
    if (registryData?.subscription_status === "SUBSCRIPTION_PENDING") {
      return false;
    }
    if (
      (registryData?.subscription_id === undefined &&
        isNaN(Number(selectedSubscription))) ||
      user?.email != null
    ) {
      return true;
    }
  };

  const renderContent = () => {
    if (isLoading) {
      return <Loading message="Cargando suscripciones Maifud..." />;
    }

    if (isError) {
      return (
        <RetryErrorMessage
          title={
            "No se pudo obtener la información de las suscripciones. Por favor intenta de nuevo."
          }
          message={error.message}
          retry={refetch}
          error={error}
        />
      );
    }

    if (data && data.subscriptions) {
      return (
        <>
          {registryData?.subscription_status === "SUBSCRIPTION_PENDING" && (
            <div className="text-center p-4 rounded-md border bg-orange-100/50 text-primary font-semibold">
              <div className="space-x-1">
                <span>🔔</span>
                <span>Tienes una suscripción pendiente por terminar</span>
                <span>🔔</span>
              </div>
              <div className="pt-2">
                <Button>
                  <Link to="/register/confirmation">Continuar registro</Link>
                </Button>
              </div>
            </div>
          )}
          {data.promo_code && (
            <div>
              <p className="text-center text-sm text-muted-foreground">
                Tenemos un descuento para ti
              </p>
              <div className="text-center p-4 rounded-md border bg-orange-100/50 text-primary font-semibold space-x-1">
                <span>🎉 </span>
                <span>{data.promo_code.disclaimer}</span>
                <span>🎉 </span>
              </div>
            </div>
          )}
          {data.partnership && (
            <div>
              <div className="text-center p-4 rounded-md border bg-orange-100/50 text-primary font-semibold space-x-1">
                <span>🎉 </span>
                <span>{data?.partnership.partnership_description}</span>
                <span>🎉 </span>
              </div>
            </div>
          )}
          {data.referrer && (
            <div className="text-center p-4 rounded-md border bg-orange-100/50 text-primary font-semibold space-x-1">
              <span>🎉 </span>
              <span>
                <strong>{data.referrer.referrer_name}</strong> te regala un
                envío gratis.
              </span>
              <span>🎉 </span>
            </div>
          )}
          {user?.email &&
            registryData?.subscription_status !== "SUBSCRIPTION_PENDING" && (
              <div className="text-center p-4 rounded-md border bg-orange-100/50 space-x-1">
                <span>
                  Ya tienes una suscripción en Maifud.{" "}
                  <Link
                    to="/user/subscription"
                    className="text-primary underline"
                  >
                    Puedes acceder a tu cuenta haciendo click aquí
                  </Link>
                </span>
              </div>
            )}
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <FormField
                control={form.control}
                name="subscription_id"
                render={({ field }) => (
                  <RadioGroup
                    className="grid gap-4 grid-cols-2 lg:grid-cols-4 lg:gap-4"
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    {data.subscriptions.map((item) => {
                      return <Subscription key={item.id} subscription={item} />;
                    })}
                  </RadioGroup>
                )}
              />
              <div className="flex justify-end py-4">
                <Button
                  type="submit"
                  className="w-full sm:w-fit"
                  disabled={isNextStepDisable()}
                >
                  Siguiente
                </Button>
              </div>
            </form>
          </Form>
        </>
      );
    }
  };

  return (
    <PageLayout
      title="Escoge la caja que más te guste"
      description="Esta va a ser tu primera caja. Podrás cambiar el tipo de caja desde tu cuenta"
      progress={10}
    >
      {renderContent()}
    </PageLayout>
  );
}

export default Subscriptions;
