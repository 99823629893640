import { Button } from "@/components/ui/button";
import { ResumeCheckoutOutput } from "@/services/types";
import { getShippingTypeName } from "@/utils/subscriptions";
import { EditIcon } from "lucide-react";
import { useState } from "react";
import { Step } from ".";
import { cn } from "@/lib/utils";
import { ButtonCustomLink } from "@/components";
import { useNavigate } from "@tanstack/react-router";
import ReactGA from "react-ga4";

interface SummaryProps extends ResumeCheckoutOutput {
  onChangeStep: (step: Step) => void;
  isLoggedIn: boolean;
}

const itemClassName =
  "border rounded-md flex justify-between items-center p-2 cursor-pointer bg-primary/10";

const Summary = (props: SummaryProps) => {
  const [redirecting, setRedirecting] = useState(false);
  const {
    subscription_name,
    subscription_subtotal,
    shipping_frecuency,
    shipping_type,
    delivery_address,
    delivery_address_number,
    delivery_district_name,
    additional_data,
    shipping_cost,
    discount_amount,
    discount,
    pickup_name,
    pickup_district_name,
    pickup_address,
    subscription_total_with_discount,
    form_shipping_first_delivery_date,
    frecuency_names,
    inscription_url,
    onChangeStep,
    isLoggedIn,
  } = props;
  const navigate = useNavigate();

  const deliveryDateIsValid = frecuency_names.includes(
    form_shipping_first_delivery_date,
  );

  const handleGoToPay = () => {
    if (inscription_url) {
      setRedirecting(true);
      ReactGA.event({
        category: "Subscription Incomplete",
        action: "Redirect to Payment",
      });
      window.location.href = inscription_url;
    }
  };

  const handleGotToLogin = () => {
    navigate({
      to: "/login",
      params: {
        redirect: "/subscription",
      },
    });
  };

  return (
    <div>
      <div className="space-y-4">
        <div>
          <p className="text-xs font-semibold">Tipo de suscripción</p>
          <div
            className={itemClassName}
            onClick={() =>
              isLoggedIn ? onChangeStep("SUBSCRIPTION") : handleGotToLogin()
            }
          >
            <p className="text-sm font-normal">
              📦 {subscription_name}
              {" - "}
              <span className="text-primary font-sans font-semibold">
                {subscription_subtotal}
              </span>
            </p>
            {isLoggedIn && (
              <div className="flex items-center gap-1">
                <small className="text-[10px] text-muted-foreground">
                  Cambiar
                </small>
                <EditIcon className="text-primary" size={18} />
              </div>
            )}
          </div>
        </div>
        {isLoggedIn && (
          <div>
            <p className="text-xs font-semibold">Fecha primer envío</p>
            <div
              className={cn(itemClassName, !deliveryDateIsValid && "bg-white")}
              onClick={() => onChangeStep("DELIVERY_DATE")}
            >
              <p className="text-sm font-normal font-sans">
                🗓️ {deliveryDateIsValid && form_shipping_first_delivery_date}
              </p>
              <div className="flex items-center gap-1">
                <small className="text-[10px] text-muted-foreground">
                  Cambiar
                </small>
                <EditIcon className="text-primary" size={18} />
              </div>
            </div>
          </div>
        )}
        <div>
          <p className="text-xs font-semibold">Frecuencia de envío</p>
          <div
            className={itemClassName}
            onClick={() =>
              isLoggedIn ? onChangeStep("FRECUENCY") : handleGotToLogin()
            }
          >
            <p className="text-sm font-normal">⏰ {shipping_frecuency}</p>
            {isLoggedIn && (
              <div className="flex items-center gap-1">
                <small className="text-[10px] text-muted-foreground">
                  Cambiar
                </small>
                <EditIcon className="text-primary" size={18} />
              </div>
            )}
          </div>
        </div>
        <div>
          <p className="text-xs font-semibold">Método de envío</p>
          <div
            className={itemClassName}
            onClick={() =>
              isLoggedIn ? onChangeStep("SHIPPING_METHOD") : handleGotToLogin()
            }
          >
            <p className="text-sm font-normal">
              {shipping_type === "DELIVERY" && (
                <>
                  <p>
                    🚚 {getShippingTypeName(shipping_type)}
                    {" - "}
                    <span className="text-primary font-sans font-semibold">
                      {shipping_cost}
                    </span>
                  </p>
                  <div className="text-xs  font-sans">
                    <p>
                      {delivery_address} {delivery_address_number}
                    </p>
                    <p>{delivery_district_name}</p>
                    {additional_data && <p>"{additional_data}"</p>}
                  </div>
                </>
              )}
              {shipping_type === "PICKUP" && (
                <>
                  <p>
                    🏬 {getShippingTypeName(shipping_type)}
                    {" - "}
                    <span className="text-primary font-sans font-semibold">
                      {shipping_cost}
                    </span>
                  </p>
                  <div className="text-xs font-semibold font-sans">
                    <p>{pickup_name}</p>
                    <p>
                      {pickup_address}, {pickup_district_name}
                    </p>
                  </div>
                </>
              )}
            </p>
            {isLoggedIn && (
              <div className="flex items-center gap-1">
                <small className="text-[10px] text-muted-foreground">
                  Cambiar
                </small>
                <EditIcon className="text-primary" size={18} />
              </div>
            )}
          </div>
        </div>
        <div className="py-4">
          <p className="text-xs font-semibold">Resumen primer pago</p>
          <div>
            <div className="flex justify-between border-b items-center py-1">
              <p className="text-xs text-muted-foreground">Costo suscripción</p>
              <p className="text-sm font-sans">{subscription_subtotal}</p>
            </div>
            <div className="flex justify-between border-b items-center py-1">
              <p className="text-xs text-muted-foreground">Costo envío</p>
              <p className="text-sm font-sans">{shipping_cost}</p>
            </div>
            {discount && (
              <div className="flex justify-between border-b items-center py-1">
                <p className="text-xs text-muted-foreground">
                  Descuento primer pedido{" "}
                  <span className="font-semibold font-sans">
                    ({discount}% dto.)
                  </span>
                </p>
                <p className="text-sm font-sans">-{discount_amount}</p>
              </div>
            )}
            <div className="flex justify-between border-b items-center py-1">
              <p className="text-xs text-muted-foreground">Total</p>
              <p className="text-sm font-sans">
                <span className="text-primary font-sans font-semibold text-lg">
                  {subscription_total_with_discount}
                </span>
              </p>
            </div>
          </div>
        </div>
        {isLoggedIn ? (
          <>
            <div>
              <ul className="space-y-2 text-xs text-muted-foreground mb-6">
                <li>
                  El primer cobro se hará ahora y luego regularmente se cobrará{" "}
                  <strong>{subscription_subtotal} + costo de envio</strong> el
                  domingo anterior a tu siguiente entrega.
                </li>
                <li>
                  Desde tu cuenta, podrás pausar o cancelar en cualquier
                  momento.
                </li>
                <li>
                  Se abrirá una nueva ventana para que ingreses tu método de
                  pago y finalices el proceso de suscripción.
                </li>
              </ul>
            </div>
            <div>
              <Button
                className="w-full"
                disabled={!deliveryDateIsValid || redirecting}
                onClick={handleGoToPay}
              >
                Continuar
              </Button>
            </div>
          </>
        ) : (
          <div className="border rounded-md p-4">
            <p className="text-center mb-2 text-sm text-muted-foreground">
              Para continuar con tu registro, por favor inicia sesión.
            </p>
            <ButtonCustomLink to="/login" className="w-full">
              Iniciar sesión
            </ButtonCustomLink>
          </div>
        )}
      </div>
    </div>
  );
};

export default Summary;
