import { createFileRoute } from "@tanstack/react-router";
import { ShippingMethod as ShippingMethodPage } from "@/pages";
import * as z from "zod";

const shippingMethodsQueryParamsSchema = z.object({
  // used to render special pickup points based on a partnetship string identifier
  ref: z.optional(z.string()),
});

export type ShippingMethodsQueryParamsSchema = z.infer<
  typeof shippingMethodsQueryParamsSchema
>;

export const Route = createFileRoute("/register/shipping-methods")({
  component: ShippingMethodPage,
  validateSearch: shippingMethodsQueryParamsSchema,
  beforeLoad: () => {
    document.title = "Maifud - Registro Métodos de despacho";
  },
});
