import { Link } from "@tanstack/react-router";
import { Menu } from "lucide-react";
import maifudLogo from "@/assets/maifud_logo.png";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTrigger,
} from "@/components/ui/sheet";
import { Footer } from "@/components";
import { sidebarNavItems } from "@/pages/User";
import { personalDataQuery } from "@/services/queries";
import { useState } from "react";

type LayoutUserProps = {
  children: React.ReactNode;
};

const LayoutUser = ({ children }: LayoutUserProps) => {
  const { data } = personalDataQuery();
  const userName = data?.data?.name || "";
  const [menuMobileIsOpen, setMenuMobileIsOpen] = useState(false);

  return (
    <>
      <div className="layout-background">
        <nav className="flex p-4 lg:my-2 justify-between lg:justify-center items-center relative">
          <Link to={"/"} className="flex items-baseline">
            <img alt="Maifud Logo" src={maifudLogo} className="w-32 lg:w-48" />
          </Link>
          <div className="translate-y-[7px] lg:hidden">
            <Sheet open={menuMobileIsOpen}>
              <SheetTrigger onClick={() => setMenuMobileIsOpen(true)}>
                <Menu size={32} className="text-muted-foreground" />
              </SheetTrigger>
              <SheetContent
                className="w-[300px]"
                onPointerDownOutside={() => setMenuMobileIsOpen(false)}
                onClose={() => setMenuMobileIsOpen(false)}
              >
                <SheetHeader>
                  <img alt="Maifud Logo" src={maifudLogo} className="w-32" />
                </SheetHeader>
                <h2 className="text-2xl font-bold tracking-tight mt-10 mb-10">
                  <div>{`Hola ${userName.split(" ")[0]}`}</div>
                </h2>
                <ul className="flex flex-col space-y-2 mt-4">
                  {sidebarNavItems.map(({ href, title, emoji }) => (
                    <li
                      className="w-full"
                      onClick={() => setMenuMobileIsOpen(false)}
                      key={title}
                    >
                      <Link
                        key={href}
                        to={href}
                        className="px-4 py-2 block text-left rounded-md"
                        activeProps={{
                          className:
                            "bg-orange-500 text-white font-semibold md:hover:bg-orange-500 md:hover:text-white",
                        }}
                      >
                        {emoji} {title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </SheetContent>
            </Sheet>
          </div>
        </nav>
        <div className="lg:mx-4">{children}</div>
      </div>
      <Footer />
    </>
  );
};

export default LayoutUser;
