import { Button } from "@/components/ui/button";
import {
  addressDataQuery,
  updateRegistryDataMutation,
} from "@/services/queries";
import { useEffect, useState } from "react";
import deliveryIcon from "@/assets/home_delivery.png";
import pickUpIcon from "@/assets/pickup_delivery.png";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { cn } from "@/lib/utils";
import { Loading } from "@/components";
import { Label } from "@/components/ui/label";
import { ExternalLinkIcon } from "lucide-react";

type InitialDataPickup = {
  shipping_type: "PICKUP";
  pickup_district_name: string | null;
  pickup_name: string | null;
};

type InitialDataDelivery = {
  shipping_type: "DELIVERY";
  delivery_district_name: string | null;
  delivery_address: string | null;
  delivery_address_number: string | null;
  additional_data: string | null;
};

type ShippingMethodProps = {
  onGoBack: () => void;
  initialData: InitialDataPickup | InitialDataDelivery;
};

const ShippingMethod = ({ onGoBack, initialData }: ShippingMethodProps) => {
  const [selectedShippingMethod, setSelectedShippingMethod] = useState(
    initialData.shipping_type,
  );
  const [selectedPickupDistrictName, setSelectedPickupDistrictName] = useState(
    initialData.shipping_type === "PICKUP"
      ? initialData.pickup_district_name
      : undefined,
  );
  const [selectedPickupPointName, setSelectedPickupPointName] = useState(
    initialData.shipping_type === "PICKUP"
      ? initialData.pickup_name
      : undefined,
  );
  const [selectedDeliveryDistrictName, setSelectedDeliveryDistrictName] =
    useState(
      initialData.shipping_type === "DELIVERY"
        ? initialData.delivery_district_name
        : undefined,
    );
  const [selectedDeliveryAddress, setSelectedDeliveryAddress] = useState(
    initialData.shipping_type === "DELIVERY"
      ? initialData.delivery_address
      : undefined,
  );
  const [selectedDeliveryAddressNumber, setSelectedDeliveryAddressNumber] =
    useState(
      initialData.shipping_type === "DELIVERY"
        ? initialData.delivery_address_number
        : undefined,
    );
  const [selectedAdditionalData, setSelectedAdditionalData] = useState(
    initialData.shipping_type === "DELIVERY"
      ? initialData.additional_data
      : undefined,
  );
  const { data: addressResponse, isLoading: addressResponseIsLoading } =
    addressDataQuery();
  const addressData = addressResponse?.data;
  const mutation = updateRegistryDataMutation();

  const pickupPointsFromSelectedDistrict = addressData?.pickup_points.filter(
    (p) => p["district_name"] === selectedPickupDistrictName,
  );
  const selectedPickupPoint = pickupPointsFromSelectedDistrict?.find(
    (p) => p.name === selectedPickupPointName,
  );

  const deliveryCost = selectedDeliveryDistrictName
    ? addressData?.districts.find(
        (d) => d.name === selectedDeliveryDistrictName,
      )?.delivery_price
    : undefined;

  const shippingDays = selectedDeliveryDistrictName
    ? addressData?.districts.find(
        (d) => d.name === selectedDeliveryDistrictName,
      )?.humanized_shipping_days
    : undefined;

  const saveButtonIsDisabled =
    mutation.isPending ||
    (selectedShippingMethod === "PICKUP" && !selectedPickupPointName) ||
    (selectedShippingMethod === "DELIVERY" && !selectedDeliveryDistrictName);

  // Redirect to summary if the mutation is successful
  useEffect(() => {
    if (mutation.isSuccess) {
      onGoBack();
    }
  }, [mutation.isSuccess]);

  useEffect(() => {
    if (!selectedPickupPoint) {
      setSelectedPickupPointName(undefined);
    }
  }, [selectedPickupDistrictName]);

  const handleChangeShippingMethodPickup = () => {
    mutation.mutate({
      form_shipping_type: "PICKUP",
      form_pickup_district_name: selectedPickupDistrictName!,
      form_pickup_name: selectedPickupPointName!,
      shipping_entity_id: addressData?.districts.find(
        (d) => d.name === selectedPickupDistrictName,
      )?.id,
    });
  };

  const handleChangeShippingMethodDelivery = () => {
    mutation.mutate({
      form_shipping_type: "DELIVERY",
      form_delivery_district_name: selectedDeliveryDistrictName!,
      form_delivery_address: selectedDeliveryAddress!,
      form_delivery_address_number: selectedDeliveryAddressNumber!,
      form_delivery_address_depto: selectedDeliveryAddressNumber!,
      form_delivery_additional_data: selectedAdditionalData!,
      shipping_entity_id: addressData?.districts.find(
        (d) => d.name === selectedDeliveryDistrictName,
      )?.id,
    });
  };

  if (addressResponseIsLoading) {
    return <Loading message="Buscando información..." />;
  }

  if (!addressData) {
    return null;
  }

  const renderPickupForm = () => {
    if (selectedShippingMethod === "PICKUP") {
      return (
        <div className="space-y-2 mb-4">
          <div>
            <Label>Comuna</Label>
            <Select
              onValueChange={(districtName) =>
                setSelectedPickupDistrictName(districtName)
              }
              defaultValue={selectedPickupDistrictName || undefined}
            >
              <SelectTrigger>
                <SelectValue placeholder="Selecciona una comuna" />
              </SelectTrigger>
              <SelectContent>
                {addressData.pickup_point_district_names.map((districtName) => (
                  <SelectItem key={districtName} value={districtName}>
                    {districtName}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div>
            <Label>Punto de retiro</Label>
            <Select
              onValueChange={(pickupName) =>
                setSelectedPickupPointName(pickupName)
              }
              defaultValue={selectedPickupPointName || undefined}
            >
              <SelectTrigger>
                <SelectValue placeholder="Selecciona un punto de retiro" />
              </SelectTrigger>
              <SelectContent>
                {pickupPointsFromSelectedDistrict?.map((pickupPoint) => (
                  <SelectItem key={pickupPoint.name} value={pickupPoint.name}>
                    {pickupPoint.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className={cn(!selectedPickupPoint && "invisible")}>
            <a
              href={`https://www.google.com/maps/place/${selectedPickupPoint?.address} - ${selectedPickupPoint?.district_name}`}
              className="text-xs cursor-pointer underline text-primary flex gap-1 items-center justify-end py-2"
              target="_blank"
            >
              {`${selectedPickupPoint?.address}, ${selectedPickupPoint?.district_name}`}
              <ExternalLinkIcon size={16} />
            </a>
          </div>
        </div>
      );
    } else {
      throw new Error("Invalid shipping type");
    }
  };

  const renderDeliveryForm = () => {
    if (selectedShippingMethod === "DELIVERY") {
      return (
        <div className="space-y-2 mb-4">
          <div>
            <Label>Comuna</Label>
            <Select
              onValueChange={(districtName) =>
                setSelectedDeliveryDistrictName(districtName)
              }
              defaultValue={selectedDeliveryDistrictName || undefined}
            >
              <SelectTrigger>
                <SelectValue placeholder="Selecciona una comuna" />
              </SelectTrigger>
              <SelectContent>
                {addressData.districts.map((district) => (
                  <SelectItem key={district.name} value={district.name}>
                    {district.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <div className="flex justify-between items-center">
              {deliveryCost && (
                <p className="text-xs text-muted-foreground mt-2">
                  Envío: <span className="font-sans">{deliveryCost}</span> -{" "}
                  {shippingDays}
                </p>
              )}
            </div>
          </div>
          <div>
            <Label>Dirección</Label>
            <Input
              onChange={(e) => {
                setSelectedDeliveryAddress(e.target.value);
              }}
              value={selectedDeliveryAddress || undefined}
              maxLength={80}
            />
          </div>
          <div>
            <Label>Número depto / Casa</Label>
            <Input
              onChange={(e) => setSelectedDeliveryAddressNumber(e.target.value)}
              value={selectedDeliveryAddressNumber || undefined}
              maxLength={80}
            />
          </div>
          <div>
            <Label>Información adicional</Label>
            <Textarea
              onChange={(e) => setSelectedAdditionalData(e.target.value)}
              value={selectedAdditionalData || undefined}
              className="text-base md:text-sm"
            />
            <p className="text-xs text-muted-foreground mt-2">
              ¿Alguna información extra que nos ayude a entregar tu pedido más
              facil y rápido?
            </p>
          </div>
        </div>
      );
    } else {
      throw new Error("Invalid shipping type");
    }
  };

  return (
    <div>
      <div className="space-y-4">
        <div className="grid grid-cols-2 gap-4 mb-4">
          <div
            className={cn(
              "border rounded-md flex flex-col items-center p-2",
              selectedShippingMethod === "DELIVERY" &&
                "bg-primary/10 border-2 border-primary",
            )}
            onClick={() => setSelectedShippingMethod("DELIVERY")}
          >
            <div className="w-full  max-w-20 pb-2">
              <img src={deliveryIcon} alt="Icono de entrega a domicilio" />
            </div>
            <p className="text-center text-sm font-semibold">
              Entrega a domicilio
            </p>
          </div>
          <div
            className={cn(
              "border rounded-md flex flex-col items-center p-2",
              selectedShippingMethod === "PICKUP" &&
                "bg-primary/10 border-2 border-primary",
            )}
            onClick={() => setSelectedShippingMethod("PICKUP")}
          >
            <div className="w-full  max-w-20 pb-2">
              <img src={pickUpIcon} alt="Icono de punto de retiro" />
            </div>
            <p className="text-center text-sm font-semibold">Punto de retiro</p>
          </div>
        </div>
        <div>{selectedShippingMethod === "PICKUP" && renderPickupForm()}</div>
        <div>
          {selectedShippingMethod === "DELIVERY" && renderDeliveryForm()}
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 pt-2">
        <Button
          variant="outline"
          disabled={mutation.isPending}
          onClick={() => onGoBack()}
        >
          Volver
        </Button>
        <Button
          disabled={mutation.isPending || saveButtonIsDisabled}
          onClick={
            selectedShippingMethod === "PICKUP"
              ? handleChangeShippingMethodPickup
              : handleChangeShippingMethodDelivery
          }
        >
          {mutation.isPending ? "Cambiando..." : "Seleccionar"}
        </Button>
      </div>
    </div>
  );
};

export default ShippingMethod;
